import React from "react"

import Layout from "@src/layout"

const Page404 = () => (
  <Layout>
    <h1>404</h1>
  </Layout>
)

Page404.propTypes = {}

Page404.defaultProps = {}

export default Page404
